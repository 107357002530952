<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Backup Management') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Backup Management') }}</h2>
      </div>

      <div class="invoice-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('ID') }}</th>
              <th scope="col">{{ $t('Backup Date') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="backup in backups">
              <td>{{ backup.id }}</td>
              <td>{{ formatDateTime(backup.backup_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#" @click="restore(backup.id)">
                    {{ $t('Restore') }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Backup from '@/lib/backup';

export default {
  name: 'Backup',
  mounted() {
    this.loadBackups();
  },
  methods: {
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    async loadBackups() {
      try {
        const loginInfo = Common.getLoginInfo();
        let backups = await Backup.loadAllBackups(this.apiUrl, loginInfo);
        this.backups = backups;
      } catch (err) {
        console.log(err);
      }
    },
    async restore(id){
      alert("Are you sure to restore the status of shop ?");
      location.reload();
    }
  },
  data() {
    return {
      backups: [],
    }
  },
  computed: {
    ...mapState({
      apiUrl: state => state.apiUrl,
    }),
  },
}
</script>

<style scoped lang="scss">
.content-header {
  display: flex;

  h2 {
    flex: 1;
  }

  .action-button {
    margin-right: 20px;
  }
}
</style>