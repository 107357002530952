import queryString from 'query-string';

const Backup = {
  async loadAllBackups(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/backup?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading backups information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadBackup(baseApiUrl, backupId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/backup/${backupId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading backup information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
}

export default Backup;
